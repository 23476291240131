<template>
  <div class="homepage">
    <div class="outVideo">
      <video autoplay="autoplay" ref="video" muted="">
        <source src="/video/startup.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div class="mask_1"></div>
      <div class="oxi">OXI RENDER</div>
      <div class="describe">从创意想法到施工落地的绝佳创作平台</div>
      <div class="bStyle" @click="downloadFile">点击下载</div>
      <!-- <el-link target="_blank" :href="url" :underline="false" style="margin-left:15px; z-index:20">
        <el-button size="mini" type="warning">软件下载</el-button>
      </el-link> -->
    </div>
    <div class="midArea" ref="midArea">
      <div class="title_1">简单的工具</div>
      <div class="title_2">其实不简单</div>
      <div class="container">
        <div class="picsArea">
          <div class="">
            <div class="box">
              <img src="/img/home/kitchen.png" alt="" />
              <div class="over-layer">
                <h3 class="title">厨房</h3>
                <!-- <p class="description">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Adipisci blanditiis tenetur veniam? Ad architecto asperiores
                  blanditiis.
                </p> -->
                <ul class="social-links">
                  <li><a href="#" class="fa fa-download"></a></li>
                  <li><a href="#" class="fa fa-link"></a></li>
                  <li><a href="#" class="fa fa-search"></a></li>
                </ul>
              </div>
            </div>
          </div>

          <div class="">
            <div class="box">
              <img src="/img/home/toliet.png" alt="" />
              <div class="over-layer">
                <h3 class="title">卫生间</h3>
                <!-- <p class="description">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Adipisci blanditiis tenetur veniam? Ad architecto asperiores
                  blanditiis.
                </p> -->
                <ul class="social-links">
                  <li><a href="#" class="fa fa-download"></a></li>
                  <li><a href="#" class="fa fa-link"></a></li>
                  <li><a href="#" class="fa fa-search"></a></li>
                </ul>
              </div>
            </div>
          </div>

          <div class="">
            <div class="box">
              <img src="/img/home/livingRoom.png" alt="" />
              <div class="over-layer">
                <h3 class="title">客厅</h3>
                <!-- <p class="description">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Adipisci blanditiis tenetur veniam? Ad architecto asperiores
                  blanditiis.
                </p> -->
                <ul class="social-links">
                  <li><a href="#" class="fa fa-download"></a></li>
                  <li><a href="#" class="fa fa-link"></a></li>
                  <li><a href="#" class="fa fa-search"></a></li>
                </ul>
              </div>
            </div>
          </div>

          <div class="">
            <div class="box">
              <img src="/img/home/porch.png" alt="" />
              <div class="over-layer">
                <h3 class="title">玄关</h3>
                <!-- <p class="description">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Adipisci blanditiis tenetur veniam? Ad architecto asperiores
                  blanditiis.
                </p> -->
                <ul class="social-links">
                  <li><a href="#" class="fa fa-download"></a></li>
                  <li><a href="#" class="fa fa-link"></a></li>
                  <li><a href="#" class="fa fa-search"></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="outVideo_2" ref="outVideo_2">
      <video autoplay="autoplay" ref="video" muted="" loop="loop">
        <source src="/video/endShow.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <div class="mask_2"></div>
      <div class="title_3">所见即所得</div>
      <div class="title_4">设计真正可交付的空间</div>
      <div class="circulars">
        <div class="circularStyle circular_1">真实产品</div>
        <div class="circularStyle circular_2">真实材质</div>
        <div class="circularStyle circular_3">可交付</div>
        <div class="circularStyle circular_4">可施工</div>
      </div>
    </div>
    <div class="homeFooter">
      <footer-component></footer-component>
    </div>
  </div>
</template>

<script>
import downLoad from '../assets/js/downLoad.js'
import '../../public/css/bootstrap.min.css'
import '../assets/css/homeImgStyle.css'
import Footer from './Footer.vue'
export default {
  name: 'Homepage',
  data() {
    return {
      videoHeight: '',
      url: 'https://3d.h5.zhaidoudou.com/pro/editor/OxiRenderSetup.exe'
    }
  },
  components: {
    'footer-component': Footer
  },
  create() {},
  mounted() {},
  methods: {
    downloadFile() {
      // this.$router.push({ path: 'https://3d.h5.zhaidoudou.com/pro/editor/OxiRenderSetup.exe' })
      window.open('https://3d.h5.zhaidoudou.com/pro/editor/OxiRenderSetup.exe')
    },
    downloadFile_1() {
      let item = {
        path: 'https://3d.h5.zhaidoudou.com/pro/editor/',
        title: 'OxiRenderSetup.exe'
      }
      // this.contextFile.index = -1;
      item.path = item.path.replace(/^http:/, 'https:')
      this.download(item.path, item.title)
    },
    download(src, fileName) {
      let x = new XMLHttpRequest()
      x.open('GET', src, true)
      x.responseType = 'blob'
      x.onload = function (e) {
        let url = window.URL.createObjectURL(x.response)
        let a = document.createElement('a')
        a.href = url
        a.download = fileName
        a.click()
      }
      x.send()
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
}
.outVideo {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 900px;
}
.mask_1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 900px;
  background-color: #1d1b1b;
  opacity: 0.3;
}
.mask_2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 1920px;
  height: 527px;
  background-color: #181818;
  opacity: 0.7;
}
video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.oxi {
  position: absolute;
  top: 326px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 100px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #ffffff;
  line-height: 0;
}
.describe {
  position: absolute;
  top: 441px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 32px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #e3e3e3;
  line-height: 0;
}

/* #21ebff */
.bStyle {
  position: absolute;
  top: 530px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 30px;
  text-decoration: none;
  text-transform: uppercase;
  transition: 0.5s;
  overflow: hidden;
  -webkit-box-reflect: below 1px linear-gradient(transparent, #0003);

  font-size: 18px;
  font-family: 'Microsoft YaHei';
  font-weight: 800;
  color: #ffffff;
  /* background: rgba(243, 134, 134, 0.5); */
}
.bStyle:hover {
  background: #6db23f;
  color: #111;
  box-shadow: 0 0 50px #6db23f;
  transition-delay: 0.5s;
  cursor: pointer;
}
.bStyle:nth-child(1) {
  filter: hue-rotate(115deg);
}

.bStyle:nth-child(3) {
  filter: hue-rotate(270deg);
}

.bStyle::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-top: 5px solid #6db23f;
  border-left: 5px solid #6db23f;
  transition: 0.5s;
  transition-delay: 0.5s;
}
.bStyle:hover::before {
  width: 100%;
  height: 100%;
  transition-delay: 0s;
}

.bStyle::after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-bottom: 5px solid #6db23f;
  border-right: 5px solid #6db23f;
  transition: 0.5s;
  transition-delay: 0.5s;
}
.bStyle:hover::after {
  width: 100%;
  height: 100%;
  transition-delay: 0s;
}

.midArea {
  position: absolute;
  top: 900px;
  left: 0;
  width: 1920px;
  height: 573px;
  background-color: #e0e0e0;
}
.title_1 {
  position: absolute;
  top: 68px;
  left: 50%;
  transform: translate(-50%);
  font-size: 40px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #333333;
}
.title_2 {
  position: absolute;
  top: 125px;
  left: 50%;
  transform: translate(-50%);
  font-size: 30px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #515050;
}
.picsArea {
  position: absolute;
  top: 233px;
  left: 50%;
  transform: translate(-50%);
  width: 1120px;
  height: 270px;
  /* background-color: rgba(117, 42, 42, 0.692); */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.el-icon-arrow-down {
  font-size: 20px;
}
.el-dropdown-link {
  cursor: pointer;
  color: #666666;
}

.outVideo_2 {
  position: absolute;
  top: 1473px;
  left: 0;
  width: 1920px;
  height: 527px;
}
.title_3 {
  position: absolute;
  top: 65px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 40px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #f3f1f1;
}
.title_4 {
  position: absolute;
  top: 120px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 30px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #dadada;
}
.circulars {
  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
  width: 1078px;
  height: 225px;
  /* background-color: rgba(192, 74, 74, 0.527); */
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.circularStyle {
  width: 225px;
  height: 225px;
  /* background-color: rgba(222, 231, 88, 0.575); */
  border-radius: 50%;
  border: 1px solid #f3f1f1;
  font-size: 30px;
  font-family: 'Microsoft YaHei';
  font-weight: 400;
  color: #e7e7e7;
  line-height: 225px;
}
.homeFooter {
  position: absolute;
  top: 2067px;
  /* position: fixed;
  bottom: 0; */
  left: 0;
  width: 1920px;
  height: 200px;
}
</style>
